import MotionBox from "~/components/MotionBox";

export default function AnimatedScalingBox(props) {
  return (
    <MotionBox
      whileHover={{
        scale: [1, 1.05, 1],
        transition: { duration: 0.4 },
      }}
      whileTap={{ scale: 0.95 }}
      {...props}
    />
  );
}
