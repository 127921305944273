import PropTypes from "prop-types";
import { useShop } from "~/context/ShopContext";
import { Box } from "@chakra-ui/react";
import { DEFAULT_SHOP_FOOTER_STYLE } from "~/utils/Constants";

import ShopFooterSimple from "~/components/shop/footer/ShopFooterSimple";
import ShopFooterDetailed from "~/components/shop/footer/ShopFooterDetailed";

ShopFooter.propTypes = {
  isInPreviewMode: PropTypes.bool,
  preferredStyle: PropTypes.string,
};

ShopFooter.defaultProps = {
  isInPreviewMode: false,
};

export default function ShopFooter(props) {
  const { shop } = useShop();
  const shopFooterStyle =
    props.preferredStyle || shop.style?.footer || DEFAULT_SHOP_FOOTER_STYLE;

  const StyledFooter = () => {
    switch (shopFooterStyle) {
      case "simple":
        return <ShopFooterSimple {...props} />;
      case "detailed":
      default:
        return <ShopFooterDetailed {...props} />;
    }
  };

  return (
    <Box w="100%" zIndex="docked">
      <StyledFooter />
    </Box>
  );
}
