import PropTypes from "prop-types";
import { useMembers } from "~/context/MembersContext";
import { useShop } from "~/context/ShopContext";
import { useUser } from "~/context/userContext";
import { Box, HStack, Avatar, AvatarGroup } from "@chakra-ui/react";
import MotionBox from "~/components/MotionBox";
import { AnimatePresence } from "framer-motion";
import { HiCursorClick } from "react-icons/hi";

const MAX_USERS_AVATARS = 3;

CollaborativeComponent.propTypes = {
  width: PropTypes.string, // Admits anything that can be passed to a Chakra's Box as Width
  height: PropTypes.string, // Admits anything that can be passed to a Chakra's Box as Height
  enabled: PropTypes.bool, // True if the component should be used, false if it should be bypassed (useful for rendering components publicly accessible)
  actionType: PropTypes.string.isRequired, // Action type that should be displayed and registered to be tracked on the backend.
  actionPaylod: PropTypes.object, // Additional object to be sent when an action is reported to be tracked on the backend
  reportActions: PropTypes.bool, // True if onClick events on the wrapped children should be tracked as an action from the currently logged user
  hidden: PropTypes.bool, // True if actions should be displayed visually, false otherwise
  showSelf: PropTypes.bool, // True if the logged user actions should be displayed, false otherwise
  large: PropTypes.bool, // True if the presence indicator should be larger than normal
  position: PropTypes.oneOf([
    "top-left",
    "top-right",
    "bottom-left",
    "bottom-right",
  ]),
  actionTrigger: PropTypes.oneOf(["click", "hover"]), // Determines wich mouse event should trigger the action reporting
};
CollaborativeComponent.defaultProps = {
  width: "100%",
  height: "100%",
  enabled: true,
  actionPaylod: null,
  reportActions: true,
  hidden: false,
  showSelf: false,
  large: false,
  position: "bottom-right",
  actionTrigger: "click",
};

export default function CollaborativeComponent({
  width,
  height,
  enabled,
  actionType,
  actionPayload,
  reportActions,
  hidden,
  showSelf,
  large,
  children,
  position,
  actionTrigger,
}) {
  // Return early if the component is not enabled
  if (!enabled) return children;

  const { shop } = useShop();
  const { user } = useUser();
  const { currentActions, trackMemberAction } = useMembers();

  // Automatically track user actions on click
  const handleClick = () => {
    if (reportActions && actionType && actionTrigger === "click")
      trackMemberAction(actionType, actionPayload);
  };

  // Automatically track user actions on click
  const handleMouseEnter = () => {
    if (reportActions && actionType && actionTrigger === "hover")
      trackMemberAction(actionType, actionPayload);
  };

  const usersActionsCollaborating = () => {
    if (actionType) {
      let userActions = currentActions.get(actionType) || [];

      // Check if the logged user actions should be displayed as a collaboration
      if (!showSelf)
        return userActions.filter(
          (userAction) => userAction.user.id !== user.uid
        );
      else return userActions;
    } else return [];
  };

  const getColor = () => {
    return usersActionsCollaborating()[0].user.accentColor;
  };

  return (
    <Box
      position="relative"
      width={width}
      height={height}
      onClick={() => handleClick()}
      onMouseEnter={() => handleMouseEnter()}
    >
      {children}
      <Box
        position="absolute"
        top="0"
        left="0"
        w="100%"
        h="100%"
        pointerEvents="none"
      >
        <AnimatePresence>
          {!hidden && usersActionsCollaborating().length > 0 && (
            <>
              <MotionBox
                key={`collaborative_component_frame_action_${actionType}`}
                position="absolute"
                w="100%"
                h="100%"
                borderRadius={shop.theme.radius}
                borderWidth="2px"
                initial={{ borderColor: "rgba(0,0,0,0)" }}
                animate={{
                  borderColor: getColor(),
                }}
                exit={{ borderColor: "rgba(0,0,0,0)" }}
              />
              <MotionBox
                key={`collaborative_component_users_action_${actionType}`}
                position="absolute"
                top={position.includes("top") ? "0" : null}
                bottom={position.includes("bottom") ? "0" : null}
                left={position.includes("left") ? "0" : null}
                right={position.includes("right") ? "0" : null}
                p="1"
                initial={{ opacity: 0, scale: 0 }}
                animate={{
                  opacity: 1,
                  scale: 1,
                }}
                exit={{ opacity: 0 }}
              >
                <HStack
                  spacing="1"
                  px="2"
                  py="1"
                  borderRadius="full"
                  backgroundColor={getColor()}
                >
                  <MousePointer />
                  <UsersAvatars
                    users={usersActionsCollaborating().map(
                      (userAction) => userAction.user
                    )}
                    large={large}
                  />
                </HStack>
              </MotionBox>
            </>
          )}
        </AnimatePresence>
      </Box>
    </Box>
  );
}
const MousePointer = () => {
  return <HiCursorClick color="#FFFFFF" />;
};

const UsersAvatars = ({ users, large }) => {
  return (
    <AvatarGroup
      size={large ? "xs" : "2xs"}
      max={MAX_USERS_AVATARS}
      borderColor="#FFFFFF"
    >
      {users.map((user, index) => (
        <Avatar
          key={`action_user_avatar_#${index}`}
          name={user.displayName}
          src={user.avatarUrl}
        />
      ))}
    </AvatarGroup>
  );
};
