import PropTypes from "prop-types";
import { useState, memo } from "react";
import DesignBlock from "~/components/designBlocks/DesignBlock";
import { Responsive, WidthProvider } from "react-grid-layout";

const LAYOUT_ROW_HEIGHT_PX = 50;
const LAYOUT_BREAKPOINT_MOBILE = 320;
const LAYOUT_BREAKPOINT_DESKTOP = 640;
const LAYOUT_COLUMNS_MOBILE = 2;
const LAYOUT_COLUMNS_DESKTOP = 12;
const LAYOUT_MIN_HEIGHT_DESKTOP = 4; // 4 x LAYOUT_ROW_HEIGHT_PX
const LAYOUT_MIN_WIDTH_DESKTOP = 2; // # Layout columns of LAYOUT_COLUMNS_DESKTOP
const LAYOUT_MIN_HEIGHT_MOBILE = 4; // 4 x LAYOUT_ROW_HEIGHT_PX
const LAYOUT_MIN_WIDTH_MOBILE = 1; // # Layout columns of LAYOUT_COLUMNS_MOBILE

const ResponsiveGridLayout = WidthProvider(Responsive);

const noOperation = () => {};

DesignLayout.propTypes = {
  layoutHash: PropTypes.string.isRequired,
  collectionItems: PropTypes.array.isRequired,
  onLayoutChange: PropTypes.func,
  isEditable: PropTypes.bool,
  onRemoveItem: PropTypes.func,
  onItemUpdated: PropTypes.func,
  onItemEdit: PropTypes.func,
  onItemCopied: PropTypes.func,
  gridMode: PropTypes.string,
};

DesignLayout.defaultProps = {
  onLayoutChange: noOperation,
  isEditable: false,
  onRemoveItem: noOperation,
  onItemUpdated: noOperation,
  onItemEdit: noOperation,
  onItemCopied: noOperation,
  gridMode: "strict",
};

function DesignLayout(props) {
  const {
    layoutHash,
    collectionItems,
    onLayoutChange,
    isEditable,
    onRemoveItem,
    onItemUpdated,
    onItemEdit,
    onItemCopied,
    gridMode,
  } = props;

  // Note: All of this burden of keeping track of the dragging state is a workarround to support Click events on the Grid Layout childrens.
  // Otherwise onClick events will be triggered even when the user is performing a drag event.
  // This is a knonw issue with the library. Unfortunaly, this workarround depends on modyfing state and thus it has a noticable impact on the UI
  // dragging animations when the user stops dragging since a UI re-render is triggered.
  // See this issues for reference:
  // https://github.com/STRML/react-grid-layout/issues/263
  // https://github.com/STRML/react-grid-layout/issues/1259
  const [isDragging, setDragging] = useState(false);
  function onDrag(e) {
    if (!isDragging) setDragging(true);
  }
  function onDragStop(layout, oldItem, newItem, placeholder, event) {
    // Hack: Since the Drag Stop executes before the OnClick of the item, we delay the sate change so isDragging is True when the item checks for its value
    // Yeah yeah, I know, its aweful.
    setTimeout(() => setDragging(false), 500);
  }

  const isGridLocked = () => gridMode === "locked";

  return (
    <ResponsiveGridLayout
      measureBeforeMount={true} // Prevents the items from moving arround on first render.
      compactType={null}
      preventCollision={gridMode === "strict" ? true : false}
      isDraggable={isGridLocked() ? false : true}
      isResizable={isGridLocked() ? false : true}
      rowHeight={LAYOUT_ROW_HEIGHT_PX}
      layouts={{
        mobile: layoutForMobileGrid(collectionItems, isEditable),
        desktop: layoutForDesktopGrid(collectionItems, isEditable),
      }}
      breakpoints={{
        mobile: LAYOUT_BREAKPOINT_MOBILE,
        desktop: LAYOUT_BREAKPOINT_DESKTOP,
      }}
      cols={{
        mobile: LAYOUT_COLUMNS_MOBILE,
        desktop: LAYOUT_COLUMNS_DESKTOP,
      }}
      margin={[0, 0]}
      onDrag={onDrag}
      onDragStop={onDragStop}
      onLayoutChange={(newLayout) => onLayoutChange(newLayout)}
    >
      {itemsForGrid(
        collectionItems,
        isEditable,
        isGridLocked(),
        isDragging,
        onRemoveItem,
        onItemUpdated,
        onItemEdit,
        onItemCopied
      )}
    </ResponsiveGridLayout>
  );
}

// The Desktop Layout is the base one. Items are stored in the DB as if they were on a desktop layout.
const layoutForDesktopGrid = (itemsList, isEditable) => {
  return itemsList.map((item) => {
    return {
      i: item.id,
      x: item.position.x,
      y: item.position.y,
      w: item.constraints?.w || item.position.w,
      h: item.constraints?.h || item.position.h,
      minH: item.constraints?.h || LAYOUT_MIN_HEIGHT_DESKTOP,
      maxH: item.constraints?.h || undefined,
      minW: item.constraints?.w || LAYOUT_MIN_WIDTH_DESKTOP,
      maxW: item.constraints?.w || undefined,
      static: !isEditable,
    };
  });
};

// The Mobile layout assumes a base Desktop layout and converts it to a more Mobile friendly version
const layoutForMobileGrid = (desktopLayoutItemsList, isEditable) => {
  // Prioritize items positions. Lower Y has priority. If Y is equal, then lower X has priority.
  const prioritizedItems = desktopLayoutItemsList.sort((itemA, itemB) => {
    if (itemA.position.y < itemB.position.y) return -1;
    if (itemA.position.y > itemB.position.y) return 1;

    if (itemA.position.x < itemB.position.x) return -1;
    if (itemA.position.x > itemB.position.x) return 1;

    return 0;
  });

  // Items width are mapped for 2 cols. Items bigger than 1/3 of the screen in desktop mode get mapped to whole Y level, otherwise the item occupies half screen.
  const normalizedWidthGridItems = prioritizedItems.map((item, index) => {
    return {
      i: item.id,
      x: item.position.x >= 6 ? 1 : 0,
      y: item.position.y,
      w: item.constraints?.w || item.position.w > 4 ? 2 : 1,
      h: item.constraints?.h || item.position.h,
      static: !isEditable,
    };
  });

  // Calculate X,Y positions of the normalized items.
  const itemsXYPositions = [];
  normalizedWidthGridItems.forEach((item, index) => {
    if (index === 0) itemsXYPositions.push({ x: 0, y: 0 });
    else {
      let x, y;

      // Case A: The prior item occupied half Y level.
      if (normalizedWidthGridItems[index - 1].w === 1) {
        // CASE A.1: The current item also occupies half Y level.
        if (normalizedWidthGridItems[index].w === 1) {
          y = itemsXYPositions[index - 1].y;

          // Case A.2 The prior item was positioned on the first column, then the current one goes to the right side without problems.
          if (itemsXYPositions[index - 1].x === 0) x = 1;
          // Case A.3 the prior item was positioned on the second column; we are gonna attempt a 'push back' of the previous item
          else {
            // Case A.3.1 two items before the actual item it was a different Y level, so that means that we can 'push back' the prior item to make space for the curren item
            if (
              itemsXYPositions[index - 2]?.y !== itemsXYPositions[index - 1].y
            ) {
              itemsXYPositions[index - 1].x = 0;
              x = 1;
            }
            // Case A.3.2 the previous Y level was occupied by two items already, so we can't 'push back', we need to move to a new Y level
            else {
              y =
                itemsXYPositions[index - 1].y +
                normalizedWidthGridItems[index - 1].h;
              x = item.x;
            }
          }
        }
        // CASE A.4: The current item wants to occupy the whole Y level.
        else {
          y =
            itemsXYPositions[index - 1].y +
            normalizedWidthGridItems[index - 1].h;
          x = 0;
        }
      }
      // Case B: The prior item occupied the whole Y level.
      else {
        y =
          itemsXYPositions[index - 1].y + normalizedWidthGridItems[index - 1].h;
        x = item.x;
      }

      itemsXYPositions.push({ x, y });
    }
  });

  // Finally, build the new Grid Items for the mobile layout.
  const dimensionizedGridItems = normalizedWidthGridItems.map((item, index) => {
    return {
      i: item.i,
      x: itemsXYPositions[index].x,
      y: itemsXYPositions[index].y,
      w: item.w,
      h: item.h,
      minH: LAYOUT_MIN_HEIGHT_MOBILE,
      minW: LAYOUT_MIN_WIDTH_MOBILE,
      static: !isEditable,
    };
  });

  return dimensionizedGridItems;
};

function itemsForGrid(
  itemsList,
  isEditable,
  gridLocked,
  isDragging,
  onRemoveItem,
  onItemUpdated,
  onItemEdit,
  onItemCopied
) {
  return itemsList.map((item) => {
    return (
      <div key={item.id} style={{ width: "100%", height: "100%" }}>
        <DesignBlock
          item={item}
          isEditable={isEditable}
          gridLocked={gridLocked}
          isDragging={isDragging}
          onRemoveClick={(itemToRemove) => onRemoveItem(itemToRemove)}
          onItemUpdated={(itemToUpdate, update) =>
            onItemUpdated(itemToUpdate, update)
          }
          onEditItemClick={(itemToEdit) => onItemEdit(itemToEdit)}
          onCopyClick={(itemToCopy) => onItemCopied(itemToCopy)}
        />
      </div>
    );
  });
}

// We use the 'memo' technique to avoid re-renderings when the general layout hashcode hasn't changed
const shouldAvoidReRender = (prevProps, nextProps) =>
  prevProps.layoutHash === nextProps.layoutHash &&
  prevProps.isEditable === nextProps.isEditable &&
  prevProps.gridMode === nextProps.gridMode;

export default memo(
  (props) => <DesignLayout {...props} />,
  shouldAvoidReRender
);
