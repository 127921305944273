import { HStack, Button, Input } from "@chakra-ui/react";
import ThemeableButton from "~/components/ThemeableButton";

export default function NumberInput(props) {
  const {
    value = 0,
    onChange = () => {},
    minValue = -999999999,
    maxValue = 999999999,
    size = "md",
    theme,
  } = props;

  return (
    <HStack w="100%">
      {theme ? (
        <ThemeableButton
          theme={theme}
          onClick={() => onChange(Math.max(minValue, parseInt(value) - 1))}
          size={size}
        >
          -
        </ThemeableButton>
      ) : (
        <Button
          onClick={() => onChange(Math.max(minValue, parseInt(value) - 1))}
          colorScheme="gray"
          size={size}
        >
          -
        </Button>
      )}
      <Input
        type="number"
        color={theme?.colors.text}
        focusBorderColor={theme?.colors.action.primary}
        borderRadius={theme ? theme.radius : "brand"}
        placeholder="Ingrese un número"
        value={value}
        size={size}
        min={minValue.toString()}
        max={maxValue.toString()}
        textAlign="center"
        onChange={(event) => {
          onChange(
            Math.min(
              maxValue,
              parseInt(Math.max(minValue, parseInt(event.target.value)))
            )
          );
        }}
      />
      {theme ? (
        <ThemeableButton
          theme={theme}
          onClick={() => onChange(Math.min(maxValue, parseInt(value) + 1))}
          size={size}
        >
          +
        </ThemeableButton>
      ) : (
        <Button
          onClick={() => onChange(Math.min(maxValue, parseInt(value) + 1))}
          colorScheme="gray"
          size={size}
        >
          +
        </Button>
      )}
    </HStack>
  );
}
