import { Center, HStack } from "@chakra-ui/react";
import { useShop } from "~/context/ShopContext";
import { FiFacebook, FiInstagram, FiTwitter, FiYoutube } from "react-icons/fi";
import { Paragraph04 } from "~/components/Texts";
import tinycolor from "tinycolor2";

export default function ShopFooterSocialNetworks() {
  const { shop } = useShop();
  const ICON_COLOR = tinycolor
    .mostReadable(
      shop.theme.colors.background.strong,
      [shop.theme.colors.text],
      {
        includeFallbackColors: true,
      }
    )
    .toHexString();

  return (
    <HStack>
      {shop.social &&
        Object.entries(shop.social)
          .filter(([_, username]) => username)
          .sort(([networkA], [networkB]) => networkA.localeCompare(networkB))
          .map(([network, username]) => (
            <Center
              key={`shop_footer_social_network_button_${network}`}
              p="1"
              borderRadius="full"
              backgroundColor={shop.theme.colors.background.strong}
              color={ICON_COLOR}
            >
              <SocialNetworkButton network={network} username={username} />
            </Center>
          ))}
    </HStack>
  );
}

const SocialNetworkButton = ({ network, username }) => {
  switch (network) {
    case "facebook":
      return (
        <a
          href={`https://www.facebook.com/${username}`}
          target="_blank"
          rel="noopener"
        >
          <Paragraph04>
            <FiFacebook />
          </Paragraph04>
        </a>
      );
    case "instagram":
      return (
        <a
          href={`https://www.instagram.com/${username}`}
          target="_blank"
          rel="noopener"
        >
          <Paragraph04>
            <FiInstagram />
          </Paragraph04>
        </a>
      );
    case "twitter":
      return (
        <a
          href={`https://www.twitter.com/${username}`}
          target="_blank"
          rel="noopener"
        >
          <Paragraph04>
            <FiTwitter />
          </Paragraph04>
        </a>
      );
    case "youtube":
      return (
        <a
          href={`https://www.youtube.com/${username}`}
          target="_blank"
          rel="noopener"
        >
          <Paragraph04>
            <FiYoutube />
          </Paragraph04>
        </a>
      );
    default:
      return null;
  }
};
