import PropTypes from "prop-types";
import { useShop } from "~/context/ShopContext";
import { useRouter } from "next/router";
import Image from "~/components/Image";
import { Box, VStack, HStack, Center, Heading } from "@chakra-ui/react";
import { Mobile, Desktop } from "~/components/MediaQuery";
import { FiShoppingCart, FiInfo } from "react-icons/fi";
import Link from "next/link";
import { formatPrice } from "~/utils/Formatters";
import ThemeableIconButton from "~/components/ThemeableIconButton";
import ThemeableButton from "~/components/ThemeableButton";
import ShopSearchButton from "~/components/search/ShopSearchButton";

ShopHeaderBoxed.propTypes = {
  isInPreviewMode: PropTypes.bool,
  product: PropTypes.array,
};

ShopHeaderBoxed.defaultProps = {
  isInPreviewMode: false,
  products: [],
};

export default function ShopHeaderBoxed({ isInPreviewMode, products }) {
  const { shop } = useShop();
  const router = useRouter();

  return (
    <VStack w="100%" px={{ base: "2", md: "4" }} zIndex="docked">
      <HStack
        w="100%"
        maxW="1400px"
        spacing="4"
        px="4"
        py="1"
        my="1"
        color={shop.theme.colors.text}
        backgroundColor={shop.theme.colors.background.lite}
        borderRadius={shop.theme.radius}
      >
        {shop.logo?.src && (
          <Link
            href={
              isInPreviewMode
                ? `/${router.query.shopDomain}/control/showcase`
                : `/${router.query.shopDomain}`
            }
          >
            <a>
              <Center position="relative" width="50px" height="50px">
                {shop.logo && (
                  <Image
                    src={shop.logo?.src}
                    objectFit="contain"
                    layout="fill"
                  />
                )}
              </Center>
            </a>
          </Link>
        )}

        <Box flex="1">
          <Link
            href={
              isInPreviewMode
                ? `/${router.query.shopDomain}/control/showcase`
                : `/${router.query.shopDomain}`
            }
          >
            <a>
              <Heading
                fontFamily={shop.theme.fonts.headline}
                maxW={{ base: "150px", md: "600px" }}
                isTruncated
              >
                {shop.displayNames.es}
              </Heading>
            </a>
          </Link>
        </Box>

        <Desktop>
          <ShopSearchButton large isDisabled={isInPreviewMode} />
        </Desktop>
        <Mobile>
          <ShopSearchButton isDisabled={isInPreviewMode} />
        </Mobile>

        <Desktop>
          <Link
            href={
              isInPreviewMode
                ? ""
                : `/${
                    router.query.shopDomain
                      ? router.query.shopDomain + "/checkout"
                      : ""
                  }`
            }
          >
            <a>
              {products.length > 0 && (
                <ThemeableButton
                  theme={shop.theme}
                  leftIcon={<FiShoppingCart />}
                  isDisabled={isInPreviewMode}
                >
                  $
                  {formatPrice(
                    products.reduce(
                      (accumulator, product) =>
                        accumulator + product.quantity * product.price.ars,
                      0
                    )
                  )}
                </ThemeableButton>
              )}
              {products.length === 0 && (
                <ThemeableIconButton
                  theme={shop.theme}
                  icon={<FiShoppingCart />}
                  isDisabled={isInPreviewMode}
                />
              )}
            </a>
          </Link>
        </Desktop>

        <Desktop>
          <Link
            href={
              isInPreviewMode
                ? ""
                : `/${
                    router.query.shopDomain
                      ? router.query.shopDomain + "/about"
                      : ""
                  }`
            }
          >
            <a>
              <ThemeableIconButton
                theme={shop.theme}
                icon={<FiInfo />}
                isDisabled={isInPreviewMode}
              />
            </a>
          </Link>
        </Desktop>
      </HStack>
    </VStack>
  );
}
