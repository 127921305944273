import dynamic from "next/dynamic";
import PropTypes from "prop-types";
import { useEffect } from "react";
import {
  HStack,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalFooter,
  Kbd,
} from "@chakra-ui/react";
import ThemeableButton from "~/components/ThemeableButton";
import ThemeableIconButton from "~/components/ThemeableIconButton";
import { FiSearch } from "react-icons/fi";
import { useShop } from "~/context/ShopContext";
import { Paragraph03, Paragraph04 } from "~/components/Texts";
import isHotkey from "is-hotkey";

// Lazy loaded components
const ProductsSearch = dynamic(
  () => import("~/components/search/ProductsSearch"),
  { ssr: false }
);

const IS_OPEN_SEARCH_SHORTCUT = isHotkey("mod+k"); // Mac: CMD+K / Linux-Windows: CTRL+K

SearchButton.propTypes = {
  large: PropTypes.bool, // Makes the SearchButton large, instea of the compact default version
  rounded: PropTypes.bool,
  isDisabled: PropTypes.bool,
};
SearchButton.defaultProps = {
  large: false,
  rounded: false,
  isDisabled: false,
};

export default function SearchButton({ large, rounded, isDisabled }) {
  const { shop } = useShop();
  const { isOpen, onOpen, onClose } = useDisclosure();

  useEffect(() => {
    // Opens/Close the sarch modal with the keyboard shortcut (cmd+k or ctrl+k)
    const handleKeyDown = (event) => {
      IS_OPEN_SEARCH_SHORTCUT(event) && !isDisabled && onOpen();
    };

    // Bind the event listener
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  return (
    <>
      {large && (
        <ThemeableButton
          theme={shop.theme}
          onClick={onOpen}
          isDisabled={isDisabled}
        >
          <HStack w="100%">
            <FiSearch />
            <Paragraph03>Buscar productos</Paragraph03>
          </HStack>
        </ThemeableButton>
      )}
      {!large && (
        <ThemeableIconButton
          theme={shop.theme}
          icon={<FiSearch />}
          onClick={onOpen}
          rounded={rounded}
          isDisabled={isDisabled}
        />
      )}

      <Modal
        isOpen={isOpen}
        onClose={onClose}
        size="2xl"
        motionPreset="slideInBottom"
      >
        <ModalOverlay />
        {isOpen && (
          <ModalContent
            backgroundColor={shop.theme.colors.background.strong}
            color={shop.theme.colors.text}
          >
            <ModalBody>
              <ProductsSearch themed />
            </ModalBody>
            <ModalFooter>
              <ShortcutHelper />
            </ModalFooter>
          </ModalContent>
        )}
      </Modal>
    </>
  );
}

const ShortcutHelper = () => {
  const { shop } = useShop();

  const isMac = () => {
    if (typeof navigator === "undefined") return false;
    else return /(Mac|iPhone|iPod|iPad)/i.test(navigator.platform);
  };

  return (
    <HStack w="100">
      <Paragraph04>Usa</Paragraph04>
      <Kbd backgroundColor={shop.theme.colors.background.strong}>
        {isMac() ? "⌘" : "Ctrl"}
      </Kbd>
      <Paragraph04>+</Paragraph04>
      <Kbd backgroundColor={shop.theme.colors.background.strong}>K</Kbd>
      <Paragraph04> para buscar</Paragraph04>
    </HStack>
  );
};
