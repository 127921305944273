import PropTypes from "prop-types";
import { useShop } from "~/context/ShopContext";
import { Box } from "@chakra-ui/react";
import { DEFAULT_SHOP_HEADER_STYLE } from "~/utils/Constants";

import ShopHeaderBoxed from "~/components/shop/header/ShopHeaderBoxed";
import ShopHeaderSimple from "~/components/shop/header/ShopHeaderSimple";
import ShopHeaderMinimal from "~/components/shop/header/ShopHeaderMinimal";

ShopHeader.propTypes = {
  isInPreviewMode: PropTypes.bool,
  products: PropTypes.array,
  style: PropTypes.string,
};

ShopHeader.defaultProps = {
  isInPreviewMode: false,
  products: [],
};

export default function ShopHeader(props) {
  const { shop } = useShop();
  const shopHeaderSyle = shop.style?.header || DEFAULT_SHOP_HEADER_STYLE;

  const StyledHeader = () => {
    switch (shopHeaderSyle) {
      case "minimal":
        return <ShopHeaderMinimal {...props} />;
      case "simple":
        return <ShopHeaderSimple {...props} />;
      default:
      case "boxed":
        return <ShopHeaderBoxed {...props} />;
    }
  };

  return (
    <Box w="100%" zIndex="docked">
      <StyledHeader />
    </Box>
  );
}
