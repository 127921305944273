import PropTypes from "prop-types";
import { Image as LocalImage } from "@chakra-ui/react";
import { Box } from "@chakra-ui/react";
import NextOptimizedImage from "next/image";
import isUrl from "is-url";

Image.propTypes = {
  src: PropTypes.string,
  scaleType: PropTypes.string,
  priority: PropTypes.bool, // If true, the image will be fetched ASAP. If false, the image will be automaticaly lazy loaded by Nextjs Image component when approiapte
  filter: PropTypes.any, // CSS filter property (eg: grayscale or blurs.)
  skipCDN: PropTypes.bool, // Forces the component to go directly to the source bypassing the CDN component. Usefull when you know beforehand that the resource won't be supported by the CDN
};

Image.defaultProps = {
  src: null,
  scaleType: "contain",
  priority: false,
  filter: null,
  skipCDN: false,
};

// If the URL is from a remote resouce, it uses Nextjs Component (taking advantage of Vercel's CDN).
// If the resource is a local one, then it uses a regular image component.
// If there is no URL, it skips the component
export default function Image({ src, scaleType, priority, filter, skipCDN }) {
  if (src) {
    // Nextjs Image Component doesn't support local images, so we need a fallback for that.
    if (!skipCDN && isUrl(src)) {
      return (
        <Box position="relative" w="100%" h="100%" sx={{ filter: filter }}>
          <NextOptimizedImage
            key={`image_${src}`}
            src={firebaseImageToBucketPath(src)}
            blurDataURL={blurredImageSrc(src)}
            layout="fill"
            placeholder="blur"
            objectFit={scaleType}
            objectPosition="50% 50%"
            draggable="false"
            priority={priority}
            quality={60}
          />
        </Box>
      );
    } else
      return (
        <LocalImage
          w="100%"
          h="100%"
          src={src}
          objectFit={scaleType}
          draggable="false"
          sx={{ filter: filter }}
        />
      );
  }

  return null;
}

// Images on Firestore are stored as publicly available URIs. This is nice.
// However ImgIX, the CDN, requires specific GCP bucket paths to work, since it doesn't use the pulic access.
// Kinda annoying, but this is how it works ¯\_(ツ)_/¯
const firebaseImageToBucketPath = (src) => {
  // Take the path after the last "/" and decode it
  if (src.includes("firebasestorage.googleapis.com"))
    return decodeURIComponent(
      src.substring(src.lastIndexOf("/") + 1, src.length)
    );
  else return src;
};

const blurredImageSrc = (src) => {
  return `https://shopstory.imgix.net/${firebaseImageToBucketPath(
    src
  )}?fit=crop&h=100&w=100&auto=compress&blur=50&auto=format`;
};
