import { Box, Center, HStack } from "@chakra-ui/react";
import Link from "next/link";
import { useShop } from "~/context/ShopContext";
import { Paragraph04 } from "~/components/Texts";
import { Desktop } from "~/components/MediaQuery";
import tinycolor from "tinycolor2";
import { useRouter } from "next/router";
import ShopFooterSocialNetworks from "~/components/shop/footer/ShopFooterSocialNetworks";

export default function Footer() {
  const { shop } = useShop();
  const router = useRouter();
  const TEXT_COLOR = tinycolor
    .mostReadable(shop.theme.colors.background.lite, [shop.theme.colors.text], {
      includeFallbackColors: true,
    })
    .toHexString();

  return (
    <Desktop w="100%">
      <Center w="100%" px="4">
        <HStack
          w="100%"
          maxW="1400px"
          p="4"
          backgroundColor={shop.theme.colors.background.lite}
          borderTopRadius={shop.theme.radius}
          spacing="4"
        >
          <Link href={`/${router.query.shopDomain}`}>
            <a>
              <Paragraph04 color={TEXT_COLOR} cursor="pointer">
                <b>{shop.displayNames.es}</b>
              </Paragraph04>
            </a>
          </Link>
          <ShopFooterSocialNetworks />
          <Box flex="1" />
          <Paragraph04 color={TEXT_COLOR}>
            © 2021 {shop.displayNames.es}. Todos los derechos reservados.
          </Paragraph04>
        </HStack>
      </Center>
    </Desktop>
  );
}
