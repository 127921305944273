import { Box, Center, Divider, HStack, VStack } from "@chakra-ui/react";
import Link from "next/link";
import { useShop } from "~/context/ShopContext";
import { Paragraph03, Paragraph04 } from "~/components/Texts";
import { Desktop } from "~/components/MediaQuery";
import tinycolor from "tinycolor2";
import { useRouter } from "next/router";
import { FiChevronRight, FiTruck } from "react-icons/fi";
import { CgCreditCard } from "react-icons/cg";
import { AiTwotoneShop } from "react-icons/ai";
import ShopFooterSocialNetworks from "~/components/shop/footer/ShopFooterSocialNetworks";

const PAYMENT_METHOD_CUSTOM = "custom";
const SHIPPING_METHOD_CUSTOM = "custom";
const SHIPPING_STORE_PICKUP = "storePickup";

export default function Footer() {
  const { shop } = useShop();
  const router = useRouter();
  const TEXT_COLOR = tinycolor
    .mostReadable(
      shop.theme.colors.background.strong,
      [shop.theme.colors.text],
      {
        includeFallbackColors: true,
      }
    )
    .toHexString();

  return (
    <Desktop w="100%">
      <Center w="100%" px="4">
        <VStack
          w="100%"
          maxW="1400px"
          p="4"
          backgroundColor={shop.theme.colors.background.lite}
          borderTopRadius={shop.theme.radius}
          color={TEXT_COLOR}
        >
          <HStack w="100%" spacing="4" pb="4" align="flex-start">
            <Box flex="1">
              <PaymentMethods />
            </Box>
            <Box flex="1">
              <ShippingMethods />
            </Box>
            <Box flex="1">
              <Location />
            </Box>
          </HStack>
          <Divider backgroundColor={shop.theme.colors.background.strong} />
          <HStack w="100%" spacing="4">
            <Link href={`/${router.query.shopDomain}`}>
              <a>
                <Paragraph04 cursor="pointer">
                  <b>{shop.displayNames.es}</b>
                </Paragraph04>
              </a>
            </Link>
            <ShopFooterSocialNetworks />
            <Box flex="1" />
            <Paragraph04>
              © 2021 {shop.displayNames.es}. Todos los derechos reservados.
            </Paragraph04>
          </HStack>
        </VStack>
      </Center>
    </Desktop>
  );
}

function PaymentMethods() {
  const { shop } = useShop();

  return (
    <HStack w="100%" align="flex-start" spacing="4">
      <Box
        p="2"
        borderRadius="full"
        backgroundColor={shop.theme.colors.background.strong}
      >
        <CgCreditCard />
      </Box>
      <VStack w="100%" align="flex-start">
        <Paragraph03>
          <b>Medios de pago</b>
        </Paragraph03>
        {Object.entries(shop.paymentMethods)
          .filter(([paymentType, paymentData]) => paymentData.enabled)
          .map(([paymentType, paymentData], index) => {
            switch (paymentType) {
              case PAYMENT_METHOD_CUSTOM:
                return (
                  <VStack
                    w="100%"
                    align="flex-start"
                    key={`payment_method_${index}`}
                  >
                    <HStack>
                      <Paragraph04>
                        <FiChevronRight />
                      </Paragraph04>
                      <Paragraph04>
                        <b>Pago directo al comercio</b>
                      </Paragraph04>
                    </HStack>
                    <Paragraph04>{paymentData.messages.es}</Paragraph04>
                  </VStack>
                );
              default:
                return null;
            }
          })}
      </VStack>
    </HStack>
  );
}

function ShippingMethods() {
  const { shop } = useShop();

  return (
    <HStack w="100%" align="flex-start" spacing="4">
      <Box
        p="2"
        borderRadius="full"
        backgroundColor={shop.theme.colors.background.strong}
      >
        <FiTruck />
      </Box>
      <VStack w="100%" align="flex-start" spacing="4">
        <Paragraph03>
          <b>Formas de envío</b>
        </Paragraph03>
        {Object.entries(shop.shippingMethods)
          .filter(([shippingType, shippingData]) => shippingData.enabled)
          .map(([shippingType, shippingData], index) => {
            switch (shippingType) {
              case SHIPPING_METHOD_CUSTOM:
                return (
                  <VStack
                    w="100%"
                    align="flex-start"
                    key={`shipping_method_${index}`}
                  >
                    <HStack>
                      <Paragraph04>
                        <FiChevronRight />
                      </Paragraph04>
                      <Paragraph04>
                        <b>Acuerdo directo con la tienda</b>
                      </Paragraph04>
                    </HStack>
                    <Paragraph04>{shippingData.messages.es}</Paragraph04>
                  </VStack>
                );
              case SHIPPING_STORE_PICKUP:
                return (
                  <VStack
                    w="100%"
                    align="flex-start"
                    key={`shipping_method_${index}`}
                  >
                    <HStack>
                      <Paragraph04>
                        <FiChevronRight />
                      </Paragraph04>
                      <Paragraph04>
                        <b>Retiro por el local</b>
                      </Paragraph04>
                    </HStack>
                    <Paragraph04>{shippingData.messages.es}</Paragraph04>
                  </VStack>
                );
              default:
                return null;
            }
          })}
      </VStack>
    </HStack>
  );
}

function Location() {
  const { shop } = useShop();

  return (
    <HStack w="100%" align="flex-start" spacing="4">
      <Box
        p="2"
        borderRadius="full"
        backgroundColor={shop.theme.colors.background.strong}
      >
        <AiTwotoneShop />
      </Box>
      <VStack w="100%" align="flex-start">
        <Paragraph03>
          <b>Dirección del local</b>
        </Paragraph03>
        <Paragraph04>
          {shop.location.address.es || (
            <i>
              En este momento no contamos con una dirección del local
              especificada.
            </i>
          )}
        </Paragraph04>
      </VStack>
    </HStack>
  );
}
