import PropTypes from "prop-types";
import { IconButton } from "@chakra-ui/react";
import tinycolor from "tinycolor2";

ThemeableIconButton.propTypes = {
  theme: PropTypes.object.isRequired,
  rounded: PropTypes.bool,
};
ThemeableIconButton.defaultProps = {
  rounded: false,
};

export default function ThemeableIconButton({ theme, rounded, ...props }) {
  const COLOR_ACTION_PRIMARY = tinycolor(theme.colors.action.primary);
  const COLOR_BACKGROUND_STRONG = tinycolor(theme.colors.background.strong);

  return (
    <IconButton
      color={tinycolor
        .mostReadable(theme.colors.background.strong, [theme.colors.text], {
          includeFallbackColors: true,
        })
        .toHexString()}
      backgroundColor={theme.colors.background.strong}
      _hover={{
        bg: COLOR_BACKGROUND_STRONG.isLight()
          ? COLOR_BACKGROUND_STRONG.darken().toHexString()
          : COLOR_BACKGROUND_STRONG.lighten().toHexString(),
      }}
      _active={{
        bg: COLOR_BACKGROUND_STRONG.darken().toHexString(),
        transform: "scale(0.98)",
        borderColor: theme.colors.action.primary,
      }}
      _focus={{
        boxShadow: `0 0 0 2px ${COLOR_ACTION_PRIMARY.toRgbString()}`,
      }}
      borderRadius={rounded ? "full" : theme.radius}
      {...props}
    />
  );
}
