import dynamic from "next/dynamic";
import { useState, useRef, memo } from "react";
import {
  Box,
  Tooltip,
  VStack,
  IconButton,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  useColorModeValue,
  LightMode,
  HStack,
  Skeleton,
} from "@chakra-ui/react";
import MotionBox from "~/components/MotionBox";
import { useShop } from "~/context/ShopContext";
import {
  FiMove,
  FiTrash2,
  FiCopy,
  FiAlertTriangle,
  FiEdit2,
} from "react-icons/fi";
import { AnimatePresence } from "framer-motion";
import { Paragraph02, Paragraph04 } from "~/components/Texts";
import { FcIdea } from "react-icons/fc";
import { MdLockOutline } from "react-icons/md";
import useOutsideClickListener from "~/context/UseOutsideClickListener";
import CollaborativeComponent from "~/components/CollaborativeComponent";
import AnimatedEntryOnViewport from "~/components/animations/AnimatedEntryOnViewport";

const DynamicComponentPlaceholder = () => <Skeleton w="100%" h="100%" />;

// Lazy loading components
const BlockProduct = dynamic(
  () => import("~/components/designBlocks/blocks/BlockProduct"),
  {
    loading: () => <DynamicComponentPlaceholder />,
  }
);
const BlockCollection = dynamic(
  () => import("~/components/designBlocks/blocks/BlockCollection"),
  {
    loading: () => <DynamicComponentPlaceholder />,
  }
);
const BlockHeading = dynamic(
  () => import("~/components/designBlocks/blocks/BlockHeading"),
  {
    loading: () => <DynamicComponentPlaceholder />,
  }
);
const BlockRichText = dynamic(
  () => import("~/components/designBlocks/blocks/BlockRichText"),
  {
    loading: () => <DynamicComponentPlaceholder />,
  }
);
const BlockImage = dynamic(
  () => import("~/components/designBlocks/blocks/BlockImage"),
  {
    loading: () => <DynamicComponentPlaceholder />,
  }
);
const BlockCarousel = dynamic(
  () => import("~/components/designBlocks/blocks/BlockCarousel"),
  {
    loading: () => <DynamicComponentPlaceholder />,
  }
);
const BlockYoutube = dynamic(
  () => import("~/components/designBlocks/blocks/BlockYoutube"),
  {
    loading: () => <DynamicComponentPlaceholder />,
  }
);

function DesignBlock({
  item,
  isDragging,
  isEditable,
  gridLocked,
  onRemoveClick = () => {},
  onItemUpdated = () => {},
  onCopyClick = () => {},
  onEditItemClick = () => {},
}) {
  const { shop } = useShop();
  const [animationVariant, setAnimationVariant] = useState("stopped");
  const [inEditMode, setInEditMode] = useState(false);
  const [isHovered, setHovered] = useState(false);

  const topLevelComponentRef = useRef(null);
  useOutsideClickListener(topLevelComponentRef, () => {
    setInEditMode(false);
  });

  const isClickeable = () => {
    return item.type === "product" || item.type === "collection";
  };

  const animationVariants = {
    movingLeft: {
      scale: 1.1,
      rotate: [3, 1],
      cursor: "grabbing",
      transition: { repeat: Infinity, duration: 0.4, repeatType: "reverse" },
    },
    movingRight: {
      scale: 1.1,
      rotate: [-3, -1],
      cursor: "grabbing",
      transition: { repeat: Infinity, duration: 0.4, repeatType: "reverse" },
    },
    hover: {
      transition: { transition: "easeIn", duration: 0.3 },
      scale: isEditable ? 1.01 : isClickeable() ? [1, 1.01, 1] : 1,
      boxShadow: isEditable
        ? gridLocked
          ? "0 0 0 4px rgba(238, 0, 5, .5)"
          : "0 0 0 4px rgba(52, 93, 238, .5)"
        : "0 0 0 0px rgba(0,0,0,0)",
    },
    stopped: {
      scale: 1,
      rotate: 0,
      transition: "easeOut",
      boxShadow: "0 0 0 0px rgba(0,0,0,0)",
    },
  };

  function onPanStart(event, info) {
    if (isEditable && !gridLocked) {
      if (info.delta.x > 0) setAnimationVariant("movingRight");
      else setAnimationVariant("movingLeft");
    }
  }

  function onPanEnd(event, info) {
    if (isEditable) setAnimationVariant("stopped");
  }

  return (
    <MotionBox
      h="100%"
      w="100%"
      onPanStart={onPanStart}
      onPanEnd={onPanEnd}
      onMouseOver={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      whileHover="hover"
      variants={animationVariants}
      initial="stopped"
      animate={animationVariant}
      p="1"
      borderRadius={shop.theme.radius}
    >
      <Box
        ref={topLevelComponentRef}
        position="relative"
        h="100%"
        w="100%"
        borderRadius={shop.theme.radius}
        backgroundColor={shop.theme.colors.background.superLite}
        boxShadow={
          isEditable ? (animationVariant === "stopped" ? "none" : "lg") : "none"
        }
        overflow="hidden"
      >
        <AnimatedEntryOnViewport animated={!isEditable}>
          <CollaborativeComponent
            actionType={`click_collection_layout_item_@${item.id}`}
            enabled={isEditable}
            position="bottom-left"
            large
            actionTrigger="hover"
          >
            <Box w="100%" h="100%" position="relative">
              {item.type === "product" && (
                <BlockProduct
                  productId={item.content.productId}
                  style={item.content.style}
                  theme={shop.theme}
                  isEditable={isEditable}
                  linked={!isDragging}
                  isHovered={isHovered}
                />
              )}
              {item.type === "collection" && (
                <BlockCollection
                  collectionId={item.content.collectionId}
                  style={item.content.style}
                  theme={shop.theme}
                  isEditable={isEditable}
                  linked={!isDragging}
                />
              )}
              {item.type === "heading" && (
                <BlockHeading
                  layoutItemId={item.id}
                  content={item.content}
                  theme={shop.theme}
                  isEditable={isEditable}
                  onChange={(update) => onItemUpdated(item, update)}
                  inEditMode={inEditMode}
                  onEditModeChange={(newEditorState) =>
                    setInEditMode(newEditorState)
                  }
                />
              )}
              {item.type === "rich_text" && (
                <BlockRichText
                  layoutItemId={item.id}
                  content={item.content}
                  theme={shop.theme}
                  isEditable={isEditable}
                  onChange={(update) => onItemUpdated(item, update)}
                  inEditMode={inEditMode}
                  onEditModeChange={(newEditorState) =>
                    setInEditMode(newEditorState)
                  }
                />
              )}
              {item.type === "image" && (
                <BlockImage
                  shopItemId={item.id}
                  layoutItemId={item.id}
                  content={item.content}
                  theme={shop.theme}
                  isEditable={isEditable}
                  onChange={(update) => onItemUpdated(item, update)}
                  inEditMode={inEditMode}
                  onEditModeChange={(newEditorState) =>
                    setInEditMode(newEditorState)
                  }
                />
              )}
              {item.type === "carousel" && (
                <BlockCarousel
                  shopItemId={item.id}
                  layoutItemId={item.id}
                  content={item.content}
                  theme={shop.theme}
                  isEditable={isEditable}
                  onChange={(update) => onItemUpdated(item, update)}
                  inEditMode={inEditMode}
                  onEditModeChange={(newEditorState) =>
                    setInEditMode(newEditorState)
                  }
                />
              )}
              {item.type === "youtube" && (
                <BlockYoutube
                  shopItemId={item.id}
                  layoutItemId={item.id}
                  content={item.content}
                  theme={shop.theme}
                  isEditable={isEditable}
                  onChange={(update) => onItemUpdated(item, update)}
                  inEditMode={inEditMode}
                  onEditModeChange={(newEditorState) =>
                    setInEditMode(newEditorState)
                  }
                />
              )}
            </Box>
          </CollaborativeComponent>
        </AnimatedEntryOnViewport>
        <AnimatePresence>
          {isEditable && isHovered && (
            <MotionBox
              h="100%"
              position="absolute"
              top="0"
              right="0"
              p="2"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
            >
              <ToolbarForItem
                item={item}
                gridLocked={gridLocked}
                onRemove={() => onRemoveClick(item)}
                onCopy={() => onCopyClick(item)}
                onEdit={() => onEditItemClick(item)}
              />
            </MotionBox>
          )}
        </AnimatePresence>
      </Box>
    </MotionBox>
  );
}

const ToolbarForItem = ({ item, gridLocked, onRemove, onCopy, onEdit }) => {
  const itemCanBeEdited = () => {
    return item.type === "collection";
  };

  const itemCanBeResized = () => {
    // Items with fixed height or width can't be resized
    return !(item.constraints?.h && item.constraints?.h);
  };

  return (
    <LightMode>
      <VStack h="100%" spacing="4">
        <DeleteBlockButton item={item} onRemoveClick={() => onRemove()} />
        <Tooltip label="Duplicar bloque" placement="left">
          <IconButton
            icon={<FiCopy />}
            colorScheme="brandLite"
            borderRadius="full"
            boxShadow="lg"
            onClick={() => onCopy()}
          />
        </Tooltip>
        {itemCanBeEdited() && (
          <Tooltip label="Editar" placement="left">
            <IconButton
              icon={<FiEdit2 />}
              colorScheme="brandLite"
              borderRadius="full"
              boxShadow="lg"
              onClick={() => onEdit()}
            />
          </Tooltip>
        )}
        <Box flex="1" />
        {itemCanBeResized() && !gridLocked && (
          <IconButton
            icon={<FiMove />}
            colorScheme="brandLite"
            borderRadius="full"
            boxShadow="lg"
          />
        )}
        {itemCanBeResized() && gridLocked && (
          <Tooltip label="Grilla bloqueada" placement="left">
            <IconButton
              icon={<MdLockOutline />}
              colorScheme="gray"
              color="feel.negative"
              borderRadius="brand"
              boxShadow="lg"
              cursor="auto"
            />
          </Tooltip>
        )}
      </VStack>
    </LightMode>
  );
};

const DeleteBlockButton = ({ item, onRemoveClick }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <Tooltip label="Eliminar bloque" placement="left">
        <IconButton
          icon={<FiTrash2 />}
          colorScheme="red"
          borderRadius="full"
          boxShadow="lg"
          onClick={onOpen}
        />
      </Tooltip>

      <Modal isOpen={isOpen} onClose={onClose} isCentered size="lg">
        <ModalOverlay />
        <ModalContent
          backgroundColor={useColorModeValue(
            "light.background.superLite",
            "dark.background.superLite"
          )}
        >
          <ModalHeader
            color={useColorModeValue("light.brand.strong", "dark.brand.strong")}
          >
            Advertencia
          </ModalHeader>
          <ModalCloseButton
            color={useColorModeValue("light.brand.strong", "dark.brand.strong")}
          />
          <ModalBody
            color={useColorModeValue("light.brand.strong", "dark.brand.strong")}
          >
            <VStack w="100%" align="flex-start" spacing="4">
              <Paragraph02>
                Se eliminará el bloque y todo su contenido. Esta acción no podrá
                deshacerse.
              </Paragraph02>
              {item.type === "product" && (
                <HStack
                  w="100%"
                  p="2"
                  backgroundColor={useColorModeValue(
                    "light.background.strong",
                    "dark.background.strong"
                  )}
                  borderRadius="brand"
                  spacing="2"
                  align="flex-start"
                >
                  <FcIdea size="24px" />
                  <Box flex="1">
                    <Paragraph04>
                      Ten en cuenta que{" "}
                      <u>
                        solamente estas eliminando el acceso a un producto desde
                        la colección actual
                      </u>
                      . Si prefieres eliminar todo el producto, deberás hacerlo
                      desde la barra superior en la página del mismo.
                    </Paragraph04>
                  </Box>
                </HStack>
              )}
              {item.type === "collection" && (
                <HStack
                  w="100%"
                  p="2"
                  backgroundColor={useColorModeValue(
                    "light.background.strong",
                    "dark.background.strong"
                  )}
                  borderRadius="brand"
                  spacing="2"
                  align="flex-start"
                >
                  <FcIdea size="24px" />
                  <Box flex="1">
                    <Paragraph04>
                      Ten en cuenta que{" "}
                      <u>
                        solamente estas eliminando el acceso a otra colección
                        desde la colección actual
                      </u>
                      . Si prefieres eliminar toda la colección, deberás hacerlo
                      desde la barra superior en la página de la misma.
                    </Paragraph04>
                  </Box>
                </HStack>
              )}
            </VStack>
          </ModalBody>

          <ModalFooter>
            <Button
              variant="ghost"
              colorScheme="brandLite"
              mr="3"
              onClick={onClose}
            >
              Cancelar
            </Button>
            <Button
              colorScheme="red"
              leftIcon={<FiAlertTriangle />}
              onClick={() => {
                onRemoveClick();
                onClose();
              }}
            >
              Eliminar bloque
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

// We use the 'memo' technique to avoid re-renderings when the item hashcode hasn't changed
const shouldAvoidReRender = (prevProps, nextProps) =>
  prevProps.item.hash === nextProps.item.hash &&
  prevProps.isDragging === nextProps.isDragging &&
  prevProps.isEditable === nextProps.isEditable &&
  prevProps.gridLocked === nextProps.gridLocked;

export default memo((props) => <DesignBlock {...props} />, shouldAvoidReRender);
